@font-face {
    font-family: 'Comfortaa';
    src: url('assets/fonts/Comfortaa/Comfortaa-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('assets/fonts/Comfortaa/Comfortaa-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('assets/fonts/Comfortaa/Comfortaa-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('assets/fonts/Comfortaa/Comfortaa-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('assets/fonts/Comfortaa/Comfortaa-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('assets/fonts/OpenSans/OpenSans-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('assets/fonts/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('assets/fonts/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('assets/fonts/OpenSans/OpenSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('assets/fonts/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('assets/fonts/OpenSans/OpenSans-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}
